import React, { useEffect } from "react";


function GoogleAuth() {
  useEffect(() => {});

  return <div>Redirecting...</div>;
}

export default GoogleAuth;
